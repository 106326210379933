.row:before, .row:after {
    display: inline-block !important;
}

.main-content{
	@include mobileMenu;
}

#content{
	padding: 0;
	padding-top: 0 !important;

	.img-wrap{
		width: 100%;
		padding-bottom: 25%;
		overflow: hidden;
		height: 0;
		background-position: center center !important;
		background-size: cover !important;

		@include media-breakpoint-down(md) {
			padding-bottom: 33%;
		}
	}

	ul{
		list-style: none;
		padding-left: 25px;
		margin-top: 15px;
		margin-bottom: 25px;
		margin-bottom: 25px;
		text-align: left;

		@include media-breakpoint-down(xs) {
			padding-left: 15px;
		}

		li{
			&:before{
			    content: "\2022";
			    color: $lightgreen;
			    font-weight: bold;
			    display: inline-block;
			    margin-right: 15px;
			    font-size: 20px;
			}
		}
	}

	ol{
		display: table;
		margin: 0 auto;
		text-align: left;
		padding: 0;
		margin-bottom: 25px;
	}

	a{
		color: $lightgreen;
	}
}

.content-page{
	padding-bottom: 5rem;

	h1{
		margin-top: 60px;
		font-size: 42px;
		color: $lightgreen;
		text-align: center;
	}

	h2{
		color: $lightgreen;
		font-size: 28px;
		margin-top: 50px;
		margin-bottom: 35px;
		display: block;
		width: 100%;
		font-weight: lighter;
	}

	.intro-content{
		img{
			max-width: 100% !important;
			height: auto !important;
			margin-top: 25px;
			margin-bottom: 50px;
		}
	}
}

.getuigenis-wrap{
	margin-top: 50px;
	background-color: $blue;
	color: $white;
	border-radius: 30px 0 30px 0;
	padding: 30px 75px;
	text-align: center;
	position: relative;

	p{
		font-size: 17px;
		font-weight: bold;
		font-style: italic;
		letter-spacing: 1px;
	}

	.auteur{
		text-align: right;

		.persoon{
			font-size: 17px;
		}

		.functie{
			font-size: 11px;
			position: relative;
			bottom: 7px;
			right: 15px;
		}
	}

	img{
		position: absolute;
		width: 40px;

		&:first-of-type{
			top: 15px;
			left: 15px;
		}

		&:last-of-type{
			bottom: 15px;
			right: 15px;
		}
	}
}

.home-section .getuigenis-wrap{
	margin-top: 0 !important;
}
				
.col-no-padding{
	padding: 0 !important;
}

