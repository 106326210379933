#nav-main{
	border-radius: 0 !important;
	margin-bottom: 0 !important;
	padding-top: 0 !important;
	padding-bottom: 0 !important;

}

#nav-main.nav-subpages{
	border-bottom: 5px solid $grey !important;
	
	.navbar-brand{
		height: auto !important;
		padding: 0 !important;
		padding-left: 15px !important;
		display: inline-block;


		img{
			max-width: 233px;
		}

		@include media-breakpoint-down(md) {
			padding: 25px 0 !important;
		}
	}

	.navbar-toggler{
		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	#menu-entries{
		@include media-breakpoint-down(md) {
			display: none !important;
		}

		.navbar-nav{
			float: right !important;

			li{
				a{
					padding: 33px 25px;
					transition: all ease-in 0.1s;
					color: $black !important;

					&:hover{
						color: $white !important;
					}
				}

				&:nth-of-type(3n-2){
					a{
						&:hover{
							background-color: $lightgreen !important;
						}
					}
				}

				&:nth-of-type(3n-1){
					a{
						&:hover{
							background-color: $green !important;
						}
					}
				}

				&:nth-of-type(3n+0){
					a{
						&:hover{
							background-color: $blue !important;
						}
					}
				}
			}
		}
	}
}


/**
 * Mobile only
**/

@include media-breakpoint-down(md) {

	#menu-entries{
		display: none !important;
	
		&.show{
			display: inherit !important;
		}
	}
}