.teamleden{
	.teamlid{
		text-align: center;
		margin-bottom: 25px !important;

		img{
			margin-bottom: 15px !important;
		}

		.naam{
			font-weight: bold;
			display: block;
		}

		a{
			color: $lightgreen !important;
		}

	}
}