// Nieuwsoverzicht

.template-nieuws{

	h1, h2, h3{
		color: $blue;
	}

	ul{
		li{
			&:before{
				color: $blue !important;
			}
		}
	}

	a{
		color: $blue !important;
	}

	.nieuws{

		.nieuws-row{
			margin-bottom: 35px;

			@include media-breakpoint-down(sm) {
				margin-bottom: 50px;
			}
		}

		.nieuws-container{
			margin-top: 50px;
			position: relative;
			height: 100%;

			&:before{
				@include colorBar;
				background-color: $blue;
				left: -15px !important;
			}

			&:after{
				@include colorBar;
				background-color: $blue;
				left: auto;
				right: -15px;
			}

			.nieuws-preview{
				display: block;
				position: relative;
				text-decoration: none;
				height: 100%;
				z-index: 10;
				/*position: relative;
				left: 5px;

				@include media-breakpoint-down(md) {
					left: 0;
				}*/

				.arrow{
					width: 25px;
					margin-top: 10px;
					margin-bottom: 5px;
				}

				.img-wrap{
					padding: 0 !important;
					width: 100%;
					height: 100%;
					min-height: 275px;
					overflow: hidden;

					@include media-breakpoint-down(md) {
						min-height: 200px;
					}

					@include media-breakpoint-down(sm) {
						min-height: 250px;
					}

					@include media-breakpoint-down(xs) {
						min-height: 200px;
					}

					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: 100% 0%;
					}

				}

				.preview-tekst{
					padding: 0 30px;
					background-color: $white;
					min-height: 100%;

					@include media-breakpoint-down(xs) {
						padding: 0 15px;
					}

					h3{
						color: $blue;
						margin-bottom: 0px;
					}

					span{
						color: #a0a9cf;
						font-style: italic;
					}

					p{
						margin-top: 10px;
						color: $black;
					}
				}

			}

			.cirkel{
				position: absolute;
				height: 775px;
				z-index: 1;
				pointer-events: none !important;

				@include media-breakpoint-down(sm) {
					display: none !important;
				}
			}

			.cirkel-links{
				top: -150px;
				left: -15px;
			}

			.cirkel-rechts{
				top: -75px;
				right: -15px;
			}

		}

		.nieuws-button{
			text-align: center;
			margin-top: 50px;

			@include media-breakpoint-down(md) {
				margin-top: 0;
			}

			.btn{
				@include cta;
				background-color: $blue;
				color: white;

				&:hover{
					background-color: rgba($blue, 0.8);
				}
			}
		}

	}

}

// Nieuwsartikel

.nieuwsartikel{
	h1, h2, h3{
		color: $blue;
	}

	ul{
		li{
			&:before{
				color: $blue !important;
			}
		}
	}

	a{
		color: $blue !important;
	}

	.date{
		position: relative;
		top: -25px;
		text-align: center;
		font-size: 12px;
		span{
			color: $blue;
			font-size: 12px;
		}
	}
}