@mixin colorBar{
	content: '';
	display: inline-block;
	width: 10px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 15;

	@include media-breakpoint-down(sm) {
		display: none !important;
	}
}

@mixin cta{
	text-transform: uppercase;
	border-radius: 15px 0 15px 0;
	transition: 0.1s all ease-in;
	padding: 8px 25px;
	font-size: 16px;
}

@mixin mobileMenu{

	.nav-mobile{
		display: none;
		padding: 0;
		border: 0 !important;

		@include media-breakpoint-down(md) {
			display: block;
			width: 100%;
			position: fixed;
			top: 0;
			left: 0;
			background-color: transparent !important;
			display: inline-block;
			z-index: 100;
			pointer-events: none;

			.navbar-toggler{
				position: absolute;
				top: 15px;
				right: 15px;
				background-color: $blue;
				border: none;
				border-radius: 0;
				padding: 7px 15px;
				display: inline-block;
				pointer-events: auto !important;
				z-index: 110;

				.navbar-toggler-icon{
				  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
				}
			}

			#menu-entries{
				display: inline-block;
				z-index: 105;
				background-color: $blue;
				border-bottom: 5px solid $blue;
				padding: 0;
				pointer-events: auto !important;

				@include media-breakpoint-down(sm) {
					padding-left: 15px;
				}

				li{
					a{
						color: $white;
						padding: 10px 15px;
						transition: all ease-in 0.1s;
						background-color: transparent;

						&:hover{
							color: $lightgreen;
						}
					}
				}
			}
		}
	}

}