.template-ruimte{

	h1, h2, h3{
		color: $green;
	}

	ul{
		li{
			&:before{
				color: $green !important;
			}
		}
	}

	a{
		color: $green !important;
	}

	.bedrijvencentra-wrapper{
		h1{
			margin: 0 auto;
			margin-bottom: 50px;
		}

		.bedrijvencentrum{
			.bedrijvencentrum-wrapper{
				height: 145px !important;
				text-align: center;
				display: table;
				width: 100%;
				padding: 0px;
				margin-bottom: 30px;

				@include media-breakpoint-down(xs) {
					height: auto;
				}

				.bedrijvencentrum-inner{
					display: table-cell;
					height: 100% !important;
					vertical-align: middle;
					background-color: #FFF;
					a{
						display: inline-block;

						img{
							max-height: 90px;
							width: auto !important;
							padding: 3px;
							max-width: 215px !important;
							margin-top: 10px;

							@include media-breakpoint-down(xs) {
								max-width: auto !important;
								max-height: auto !important;
								width: 100%;
							}
						}
					}
				}
			}
		}
	}

	// Kaart

	.mapsvg-tooltip{

		border-color: $green;
		border-radius: 0;
		max-width: auto !important;
		padding-bottom: 15px;

		h4{
			color: $green;
			font-size: 14px;
			margin-bottom: 0 !important;
		}

		.gegevens{
			display: inline-block;
			position: relative;
			top: 15px;

			span{
				display: block;
				font-size: 10px;
			}
		}

		img{
			display: inline-block;
			max-height: 50px;
			max-width: 150px;
			margin-right: 15px;
		}

	}

	.mapsvg-sidebar-left{
		flex-basis: 40% !important;
		padding-right: 75px;

		.selection{
			margin-top: 50px !important;

			.select2-selection{
				margin-top: 25px !important;
				border: none;
				border-bottom: 3px solid $green;
				border-radius: 0;

				span{
					border: none !important;
				}

				.select2-selection__arrow{
					top: 26px !important;
					
					b{
						border-color: $green transparent transparent transparent !important;
						
					}

				}

				.select2-selection__clear{
					position: relative;
					top: 6px;
					right: 5px;
				}
			}
		}
	}
}
