/**
 * Global variables
 **/

$image_path: 'images/';

/**
 * Colors
 **/

$blue: #364b9b;
$green: #3da736;
$lightgreen: #8ab31d;
$grey: #ebebeb;

$white: #FFFFFF;
$black: #000000;