footer{
	.menu{
		list-style-type: none;
		padding: 0;
		margin-bottom: 25px;

		li{

			a{
				text-decoration: none;
				color: $blue;
				font-size: 17px;
				transition: all ease-in 0.1s;

				&:hover{
					color: $lightgreen;
				}
			}
		}
	}

	#menu-footer-menu-1{
		@include media-breakpoint-down(sm) {
			margin-bottom: 0;
		}
	}

	.logo-pomov{
		text-align: right;

		img{
			max-width: 133px;
		}

		@include media-breakpoint-down(sm) {
			text-align: left;
			margin: 25px 0px 35px 0;
		}
	}

	.footer-bar{
		background-color: $blue;
		padding: 5px 0;
		color: $white !important;

		@include media-breakpoint-down(sm) {
			padding-top: 25px;
			padding-bottom: 15px;
		}

		.linkedin{
			img{
				width: 25px;
			}

			@include media-breakpoint-down(sm) {
				display: block;
				text-align: center;
				margin-bottom: 10px;
			}
		}


		.e-mail{
			color: $white !important;
			padding-left: 50px;
			position: relative;
			top: 3px;

			@include media-breakpoint-down(sm) {
				padding-left: 0;
				position: static;
				display: block;
				text-align: center;
			}

		}

		.footer-col-2{

			text-align: right;

			span{
				text-align: right;
				font-style: italic;
				position: relative;
				top: 3px;

				@include media-breakpoint-down(sm){
					display: block;
					text-align: center;
					margin-top: 15px;
				}

				a{
					color: $white;
				}
			}

		}
	}
}