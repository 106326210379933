@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap');

*{
	font-family: 'Work Sans', sans-serif;
	line-height: 1.66em;
	font-size: 14px;

	@media screen and (min-width: 1550px) {
		font-size: 16px;
	}
}

strong, b{
	font-weight: 700;
}

h2{
	font-size: 36px;
}