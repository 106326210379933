.homepage{

	@include mobileMenu;

	.home-intro{
		position: relative;

		@include media-breakpoint-down(sm) {
			&:before{
				content: '';
				width: 100%;
				height: 10px;
				background-color: $blue;
				position: absolute;
				top: 0;
				z-index: 1;
				display: inline-block;
			}
		}

		.intro-tekst{

			position: relative;
			padding-top: 40px;
			padding-bottom: 20px;

			.logo-wrap{

				padding: 0 85px 0 75px;
				text-align: center;

				img{
					max-width: 250px;
				}

				@include media-breakpoint-down(sm) {
					padding: 0 185px 0 175px;
					text-align: center;

					img{
						max-width: 300px;
					}
				}

				@include media-breakpoint-down(xs) {
					padding: 0 105px 0 95px;
					

					img{
						max-width: 250px;
					}
				}

				@media screen and (max-width: 375px) {
					padding: 0 65px 0 55px;
				}	

				img{
					width: 100%;
					margin-bottom: 25px;
				}
			}

			p{
				padding: 0 50px 0 40px;
				text-align: center;

				@include media-breakpoint-down(xs) {
					padding: 0 25px 15px;
				}

				strong, b{
					color: $lightgreen;
				}
			}

			&:before{
				@include colorBar;
				background-color: $blue;
			}

		}

		.home-img-wrap{

			position: relative;
			height: 100%;
			width: 100%;

			@include media-breakpoint-down(sm) {
				height: 350px;
			}

			@include media-breakpoint-down(xs) {
				height: 250px;
			}

			.home-img{

				height: 100%;
				background-size: cover;
				background-position: center;
				filter: grayscale(100%);
				position: relative;

			}

			.overlay{
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background-image: linear-gradient(135deg, #6682CB 0%, #3AAA35 100%);
				mix-blend-mode: multiply;
			}

			.home-nav{

				position: absolute;
				top: 0;
				right: 0;
				padding-top: 50px;

				@include media-breakpoint-down(md) {
					display: none;
				}

				#nav-main{
					padding: 0;
					border: 0;
					padding-right: 35px;
					background-color: transparent !important;

					@include media-breakpoint-down(lg) {
						padding-right: 40px;
					}

					#menu-entries{
						padding: 0;

						li{
							a{
								color: $white;
								font-size: 17px;
								padding: 0 20px;

								&:after{
									content: '';
									display: block;
									margin-top: 5px;
									width: 0;
									height: 2px;
									background: $white;
									transition: all ease-in 0.1s;
								}

								&:hover{
									background-color: transparent !important;

									&:after{
										width: 100%;
									}
								}

								&:focus{
									background-color: transparent !important;
								}

								@include media-breakpoint-down(lg) {
									font-size: 16px;
									padding: 0 15px;
								}

							}
						}
					}
				}

			}

		}

	}

	.home-section{
		margin-top: 5rem;
		margin-bottom: 5rem;

		@include media-breakpoint-down(lg) {
			margin-top: 3rem;
			margin-bottom: 3rem;
		}
	}

	.ruimte{

		.ruimte-tekst{
			padding: 0 75px 0 65px;
			padding-bottom: 75px; //height btn + margin
			text-align: center;
			min-height: 100%;
			position: relative;

			@include media-breakpoint-down(lg) {
				padding-bottom: 0;
				margin-bottom: 35px;
				padding: 0 75px 0 65px;
			}

			@include media-breakpoint-down(xs) {
				padding-bottom: 0;
				margin-bottom: 35px;
				padding: 0 45px 0 35px;
			}

			h2{
				color: $green;
			}

			img{
				width: 60px;
				margin-bottom: 15px;
			}

			p{
				margin-bottom: 25px;	
			}

			.btn{
				@include cta;
				color: $white;
				background-color: $green;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);

				@include media-breakpoint-down(lg) {
					position: static;
					transform: none;	
				}


				&:hover{
					background-color: rgba($green, 0.8);
				}
			}

			&:before{
				@include colorBar;
				background-color: $green;

				@include media-breakpoint-down(md) {
					display: none;
				}
			}
		}

		.kaart-wrap{
			background-color: $green;
			width: 100%;
			height: 100%;
			border-radius: 0 0 0 30px;

			@include media-breakpoint-down(md) {
				padding: 0 15px;
			}

			@include media-breakpoint-down(sm) {
				border-radius: 0;
			}

			.kaart-tekst{
				padding-top: 50px;

				@include media-breakpoint-down(lg) {
					padding-top: 25px;
					padding-bottom: 25px;
				}

				@include media-breakpoint-down(md) {
					text-align: center;
				}

				p{
					max-width: 80%;

					@include media-breakpoint-down(lg) {
						max-width: 100%;
					}
				}

				.btn{
					@include cta;
					color: $black;
					background-color: #a3d8a9;
					margin-top: 20px;

					@include media-breakpoint-only(lg) {
						padding: 8px 15px;
						font-size: 14px;
					}

					@include media-breakpoint-only(md) {
						padding: 8px 15px;
						font-size: 14px;
					}

					&:hover{
						background-color: rgba(163, 216, 169, 0.8);
						color: $white;
					}

					br{
						display: none;

						@media screen and (max-width: 375px) {
							display: block;
						}
					}

				}
			}

			a{
				display: block;

				img{
					width: 100%;
					padding: 20px 0;

					@media screen and (min-width: 1550px) {
						width: 66%;
					}

					@include media-breakpoint-down(sm) {
						padding: 20px 80px;
					}

					@include media-breakpoint-down(xs) {
						padding: 20px 50px;
					}

				}

			}
		}

	}

	.infographic{

		.infographic-sub{
			position: relative;

			.img-wrap{
				display: block;
				width: 100%;
				position: relative;

				@media screen and (min-width: 1400px) {
					padding: 0 100px;
				}

				img{
					max-width: 100%;
					pointer-events: none !important;
				}
			}

			&:before{
				content: '';
				width: 10px;
				height: 100%;
				position: absolute;
				left: 0;
			}

			&:first-of-type{
				&:before{
					background-color: $grey;
				}
			}

			&:nth-of-type(2){
				&:before{
					background-color: $lightgreen;
				}
			}

			&:nth-of-type(3){
				&:before{
					background-color: $blue;
				}
			}

			&:nth-of-type(4){
				&:before{
					background-color: $green;
				}
			}

			&:nth-of-type(5){
				&:before{
					background-color: $grey;

					@include media-breakpoint-down(sm) {
						display: none !important;
					}
				}
			}
			
		}

		.infographic-wrap{
			text-align: center;
			margin-top: 25px;
			.btn{
				width: auto;
				@include cta;
				background-color: $grey;
				color: $black;

				&:hover{
					background-color: rgba($grey, 0.8);
				}
			}
		}

	}

	.nieuws{

		.nieuws-title{
			text-align: center;
			margin-bottom: 50px;
			h2{
				color: $blue;
			}
		}

		.nieuws-desktop{
			@include media-breakpoint-down(md) {
				display: none;
			}
		}

		.nieuws-mobile{
			display: none;

			@include media-breakpoint-down(md) {
				display: block;
			}

			.nieuws-row{
				margin-bottom: 35px;

				@include media-breakpoint-down(sm) {
					margin-bottom: 50px;
				}
			}
		}

		.nieuws-container{
			position: relative;
			height: 100%;

			&:before{
				@include colorBar;
				background-color: $blue;
				left: -15px !important;
			}

			&:after{
				@include colorBar;
				background-color: $blue;
				left: auto;
				right: -15px;
			}

			.nieuws-preview{
				display: block;
				position: relative;
				text-decoration: none;
				height: 100%;
				z-index: 10;

				.arrow{
					width: 25px;
					margin-top: 10px;
					margin-bottom: 5px;
				}

				.img-wrap{
					padding: 0 !important;
					width: 100%;
					height: 100%;
					min-height: 275px;
					overflow: hidden;

					@include media-breakpoint-down(md) {
						min-height: 200px;
					}

					@include media-breakpoint-down(sm) {
						min-height: auto;
						height: 250px;
					}

					@include media-breakpoint-down(xs) {
						height: 200px;
					}

					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: 100% 0%;
					}

				}

				.preview-tekst{
					padding: 0 30px;
					background-color: $white;
					min-height: 100%;

					@include media-breakpoint-down(xs) {
						padding: 0 15px;
					}

					h3{
						color: $blue;
						margin-bottom: 0px;
					}

					span{
						color: #a0a9cf;
						font-style: italic;
					}

					p{
						margin-top: 10px;
						color: $black;
					}
				}

			}

			.cirkel{
				position: absolute;
				height: 775px;
				z-index: 1;
				pointer-events: none !important;

				@include media-breakpoint-down(sm) {
					display: none !important;
				}
			}

			.cirkel-links{
				top: -150px;
				left: -15px;
			}

			.cirkel-rechts{
				top: -75px;
				right: -15px;
			}

		}

		.nieuws-button{
			text-align: center;
			margin-top: 50px;

			@include media-breakpoint-down(md) {
				margin-top: 0;
			}

			.btn{
				@include cta;
				background-color: $blue;
				color: white;

				&:hover{
					background-color: rgba($blue, 0.8);
				}
			}
		}

	}

	.evenementen{
		border-top: 2px solid $lightgreen;
		border-bottom: 2px solid $lightgreen;

		.evenementen-title{
			text-align: center;
			margin-top: 35px;
			margin-bottom: 35px;
			h2{
				color: $lightgreen;
			}
		}

		.evenement-preview{
			.title-link{
				text-decoration: none;

				h3{
					font-weight: bold;
					color: $lightgreen;
				}
			}

			.meer-info{
				color: $lightgreen;
				text-decoration: none;
				font-size: 17px;
				display: inline-block;

				&:after{
					content: '';
					display: block;
					margin-top: 5px;
					width: 0;
					height: 2px;
					background: $lightgreen;
					transition: all ease-in 0.1s;
				}

				&:hover{
					background-color: transparent !important;

					&:after{
						width: 100%;
					}
				}
			}
		}

		.evenement-info{
			height: 100%;
			background-color: $lightgreen;
			border-radius: 30px 0 30px 0;
			padding: 20px 30px;
			color: $white;
		}

		.evenementen-button{
			margin-top: 35px;
			margin-bottom: 35px;
			text-align: center;

			.btn{
				@include cta;
				background-color: $lightgreen;
				color: $white;

				&:hover{
					background-color: rgba($lightgreen, 0.8);
				}
			}
		}

	}

}