// Evenementen overzicht

.template-evenementen{

	h1, h2, h3{
		color: $lightgreen;
	}

	.evenementen-wrap{
		position: relative;

		.evenement{
			margin-top: 100px;

			.evenement-preview{
				.title-link{
					text-decoration: none;

					h3{
						font-weight: bold;
						color: $lightgreen;
					}
				}

				.meer-info{
					color: $lightgreen;
					text-decoration: none;
					font-size: 17px;
					display: inline-block;

					&:after{
						content: '';
						display: block;
						margin-top: 5px;
						width: 0;
						height: 2px;
						background: $lightgreen;
						transition: all ease-in 0.1s;
					}

					&:hover{
						background-color: transparent !important;

						&:after{
							width: 100%;
						}
					}
				}
			}

			.evenement-info{
				height: 100%;
				background-color: $lightgreen;
				border-radius: 30px 0 30px 0;
				padding: 20px 30px;
				color: $white;
			}
		}
	}
}

// Single evenementen

.evenement-page{
	h1, h2, h3{
		color: $lightgreen;
	}

	.evenement-info{
		height: 100%;
		background-color: $lightgreen;
		border-radius: 30px 0 30px 0;
		padding: 20px 30px;
		color: $white;
	}
}